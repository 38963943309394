<template>
  <div>
    <!-- 中文 -->
    <div v-if="$store.state.show">
      <h2>iOS相关</h2>
      <h3>
        1. Build Settings 通用设置:
        <ul class="ul_style">
          <li>Other Linker Flags：-ObjC</li>
          <li>Enable BitCode：No</li>
        </ul>
      </h3>
      <h3>2. 测试机需保持网络通畅并打开 APP 的网络权限</h3>
      <h3>
        3. 应用名称、包名(Bundle Identifier)和 Key
        需一一对应，否则初始化失败，提示“无效证书“、”无效包
        名”或者“非法的应用名”。（注意：包名是否存在空格）
      </h3>
      <h3>4.TiSDKResource.bundle 名称及内部文件结构、名称不可更改</h3>
      <h3>
        5. UI 相关代码中调用的 Masonry 或 ZipArchive
        中的接口函数会因版本不同而改动，如遇找不到接口函数等问题，可考虑更换匹配相应版本的函数
      </h3>
      <h3>
        6. TiSDK_iOS 文件夹中的 <span>Masonry.framework</span>，<span
          >ZipArchive.framework</span
        >
        是从相应 官方 Git 上工程打包得到的静态库，打包时
        <span>Mach-O Type</span> 设置为 <span>Static Library</span>
        （https://github.com/Masonry/Masonry，https://github.com/ZipArchive/ZipArchive
      </h3>
    </div>
    <!-- 英文 -->
    <div v-else>
      <h2>IOS related</h2>
      <h3>
        1. Build Settings :
        <ul class="ul_style">
          <li>Other Linker Flags：-ObjC</li>
          <li>Enable BitCode：No</li>
        </ul>
      </h3>
      <h3>
        2. Test machine needs to keep network smooth and open APP network
        permissions
      </h3>
      <h3>
        3. Bundle display name, Bundle Identifier, and Key need to correspond
        one to one, otherwise initialization fails, prompting “无效证书(Invalid
        KEY)”, “无效包名(Invalid Bundle Identifier)” or “非法的应用名(Invalid
        Bundle display name)”. (Note: Whether there are spaces in Bundle
        Identifier)
      </h3>
      <h3>
        4. The TiSDKResource.bundle name and internal file structure, name
        cannot be changed
      </h3>
      <h3>
        5. Interface functions in Masonry or ZipArchive called in UI-related
        code may change depending on the version. If no interface functions are
        found, consider replacing functions that match the corresponding version
      </h3>
      <h3>
        6. <span>Masonry.framework</span>，<span>ZipArchive.framework</span>of
        the TiSDK_iOS folders are from the corresponding Static library packaged
        from project on official Git with <span>Mach-O Type</span> set to
        <span>Static Library</span>
        （https://github.com/Masonry/Masonry，https://github.com/ZipArchive/ZipArchive
      </h3>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
h3 {
  font-size: 16px;
  font-weight: 500;
  color: #3c4858;
  margin: 20px 0;
  line-height: 32px;
  .ul_style {
    margin-left: 40px;
    li {
      list-style-type: disc;
      font-weight: 400;
    }
  }
}
span {
  color: #e91e63;
}
</style>